
    import { defineComponent } from "vue"

    export default defineComponent({
        props: {
            value: {
                type: String,
                default: () => 'Warning!'
            },

        },
        components: {

        },
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {

        },
    })

    