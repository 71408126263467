import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-009a033c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs flex items-center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
      return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass(["tab px-4 py-2 cursor-pointer flex-grow text-center", {
                'active bg-gray-200': _ctx.value ? _ctx.value === tab.value : i === 0,
            }]),
        key: i,
        onClick: ($event: any) => (_ctx.$emit('update:value', tab.value))
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(tab.label), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}