import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "file",
      onChange: _cache[0] || (_cache[0] = (event) => _ctx.transcribe(event)),
      accept: _ctx.acceptedFileTypes
    }, null, 40, _hoisted_1)
  ]))
}