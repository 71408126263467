
    import { defineComponent, PropType } from "vue"
    ;
    import InputText from "../misc/InputText.vue";
    import ls from "local-storage";

    export default defineComponent({
        props: {
            localStorageKey: {
                type: String,
                default: () => "apiKey"
            },
            useLocalStorage: {
                type: Boolean,
                default: () => false
            },
            showWarn: {
                type: Boolean,
                default: () => false
            },
            value: {
                type: String,
                default: () => ""
            },

        },
        components: {
            InputText
        },
        // emits: ['update:modelValue'], this.$emit('update:modelValue', title)
        data() {
            return {
                ls,

            };
        },
        computed: {

        },
        methods: {

        },

        created () {
            if (this.useLocalStorage) {
                this.$emit('update:value', ls(this.localStorageKey));
            }
        },
    });

    