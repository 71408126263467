import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47cd8c20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-text" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: "mr-2"
      }, _toDisplayString(_ctx.label), 9, _hoisted_2)
    ]),
    _createElementVNode("textarea", _mergeProps({
      type: "text",
      class: "border-2 rounded-lg p-1",
      ref: "input",
      id: _ctx.id,
      value: _ctx.value
    }, { ..._ctx.$attrs, class: 'w-full' }, {
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      onKeypress: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('end')), ["enter"])),
      onInput: _cache[1] || (_cache[1] = (ev) => _ctx.$emit('update:value', ev.target?.value))
    }), null, 16, _hoisted_3)
  ]))
}