
    import { defineComponent, computed } from 'vue'

    export default defineComponent({
        name: 'ToggleSwitch',
        props: {
            disabled: {
                type: Boolean,
                default: () => false
            },
            value: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
        },
        setup(props, { emit }) {
            const checked = computed({
                get: () => props.value,
                set: (value) => emit('update:value', value),
            })

            function updateValue() {
                emit('change', checked.value)
            }

            return { checked, updateValue }
        },
        data() {
            return {
                id: `toggle-${Math.random().toString(36).slice(2, 10)}`,
            }
        },
        computed: {

        },
        methods: {

        },
    })
  