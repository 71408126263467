import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-numder" }
const _hoisted_2 = { class: "mr-2" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        class: "border-2 px-2 py-1 rounded w-full",
        type: "number",
        placeholder: "enter value",
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', +$event.target?.value)))
      }), null, 16, _hoisted_4)
    ])
  ]))
}