
    import { defineComponent } from "vue";
    import SpeechRecording from "../audio/SpeechRecording.vue";

    export default defineComponent({
        props: {
            placeholder: String,
            label: String,
            value: String,
            disabled: Boolean,
            isLoading: Boolean,
            showSpeechRecording: Boolean,
            apiKeyNeeded: Boolean,
        },
        components: {
            // SpeechRecording,
        },
        data() {
            return {
                id: "input-text" + +new Date(),
            };
        },
        computed: {},
        methods: {},

        mounted() {
            this.$emit("mounted", this.$refs.input);
        },
    });
