
    import { defineComponent, PropType } from "vue";

    export interface ITab {
        label: string;
        value: string;
    }

    export default defineComponent({
        props: {
            tabs: Object as PropType<ITab[]>,
            value: String,
        },
        components: {},
        data() {
            return {};
        },
        computed: {},
        methods: {},

        watch: {},
    });
