
    import { defineComponent, PropType } from "vue";
    import Accordeon from "@/components/misc/Accordeon.vue"
    ;
    import InputAPIKey from "../openai/InputAPIKey.vue";

    export default defineComponent({
        props: {
            value: String,

        },
        components: {
            Accordeon,
            InputAPIKey
        },
        // emits: ['update:modelValue'], this.$emit('update:modelValue', title)
        data() {
            return {
                apiKeyVisible: false,

            };
        },
        computed: {

        },
        methods: {

        },

        created () {
            if (!this.value) {
                this.apiKeyVisible = true;
            }
        },
    });

    