
    import SimpleGPT from "./api/openai";
    import { defineComponent } from "@vue/runtime-core";
    import Error from "./components/misc/Error.vue";
    import InputTextarea from "./components/misc/InputTextarea.vue";
    import Tabs, { ITab } from "./components/misc/Tabs.vue";
    import OpenAITextSettings from "./components/openai/OpenAITextSettings.vue";
    import InputFile from "./components/misc/InputFile.vue";
    import Accordeon from "./components/misc/Accordeon.vue";
    import ls from "local-storage";
    import InputText, { InputTextSuggestion } from "./components/misc/InputText.vue";
    import { languageCodes } from "./helpers";
    import APIKey from "@/components/partials/APIKey.vue";

    export default defineComponent({
        components: {
            APIKey,
            InputText,
            Tabs,
            InputTextarea,
            OpenAITextSettings,
            InputFile,
            Accordeon,
            Error,
        },
        data() {
            return {
                models: [] as string[],
                audioFile: null,
                savedSettings: false,
                whisperLanguage: "",
                error: "",
                guides: {
                    code: "https://platform.openai.com/docs/guides/code",
                    text: "https://platform.openai.com/docs/guides/completion",
                    image: "https://platform.openai.com/docs/guides/images/introduction",
                },
                settings: false,
                textOpts: {
                    temperature: 0,
                    max_tokens: 200,
                    n: 1,
                    model: "gpt-3.5-turbo-0301",
                    stream: true,
                },
                imageOpts: {
                    n: 1,
                },
                tab: "",
                showSpeechRecording: true,
                isTranscribing: false,
                tabs: [
                    { label: "Text", value: "" },
                    // { label: "Code", value: "code" },
                    { label: "Image", value: "image" },
                    { label: "Audio", value: "audio" },
                ] as ITab[],
                result: "",
                isLoading: false,
                waitResponse: true,
                prompt: "",
                text: "",
                apiKey: process.env.OPENAI_API_KEY || ls("openAIKey") || "",
                api: new SimpleGPT({ key: process.env.OPENAI_API_KEY || "" }),
                apiKeyNeeded: false,
            };
        },
        computed: {
            tokensCount(): number {
                return this.prompt.length / 4
            },

            langCodes(): InputTextSuggestion[] {
                return languageCodes.map((code) => ({
                    name: code,
                    value: code,
                }));
            },

            currentGuide(): string {
                return (
                    (this.guides as { [key: string]: string })[this.tab] ||
                    this.guides.text
                );
            },
        },
        methods: {
            async updateApiKey(val: string) {
                // (api.setApiKey(val), (apiKeyNeeded = false))
                this.apiKey = val;
                this.api.setApiKey(val);
                this.models = (await this.api.getModels()) || [];
            },

            stopRunning() {
                if (this.apiKeyNeeded) {
                    alert("Enter your API KEY");
                    return null;
                }
            },
            setPrompt(data: string): void {
                this.prompt = data;
            },
            showSettings() {
                this.settings = !this.settings;
            },
            saveSettings() {
                localStorage.setItem("settings", JSON.stringify(this.textOpts));
                this.savedSettings = true;
                setTimeout(() => {
                    this.savedSettings = false;
                }, 5000);
            },
            clearResult() {
                this.result = "";
                this.prompt = "";
                this.error = "";
            },
            async runTranscribe(val: any) {
                if (!this.apiKey) {
                    alert("You need to set your API KEY before running");
                    return null;
                }
                if (!this.isTranscribing) {
                    this.error = "";
                    this.isTranscribing = true;
                    try {
                        const blob = new Blob([val.target.files[0]], {
                            type: "audio/webm",
                        });
                        const formData = new FormData();
                        formData.append("file", blob, "test.webm");
                        formData.append("model", "whisper-1");
                        formData.append("language", this.whisperLanguage);

                        const text = await this.api.transcribe(formData);
                        this.result = text || "";
                        setTimeout(() => {
                            (this.$refs?.result as any)?.scrollIntoView?.();
                        }, 0);
                    } catch (e: any) {
                        console.error("App error: " + e, e.response);
                        if (e?.response?.data?.error?.message) {
                            this.error = e?.response.data.error.message;
                        }
                    } finally {
                        this.isTranscribing = false;
                    }
                }
            },
            scrollToResult() {
                setTimeout(() => {
                    (this.$refs?.result as any)?.scrollIntoView?.();
                    (this.$refs?.result as any)?.$el?.scrollIntoView?.();
                }, 0);
            },
            async run() {
                if (!this.apiKey) {
                    alert("You need to set your API KEY before running");
                    return null;
                }
                if (!this.isLoading) {
                    this.result = "";
                    this.error = "";
                    this.isLoading = true;
                    try {
                        let res: any = null;
                        if (this.tab === "image") {
                            res = await this.api.getImages(this.prompt, this.imageOpts.n);
                            this.result = res || "";
                            this.scrollToResult();
                        } else if (!this.textOpts.stream) {
                            res = await this.api.get(this.prompt, this.textOpts);
                            this.result = res || "";
                            this.scrollToResult();
                        } else {
                            const fData = (delta: string, json: any, raw: string) => {
                                try {
                                    this.result += delta || "";
                                    this.scrollToResult();
                                } catch (e) {
                                    console.error("Error parsing data", e, raw);
                                }
                            };
                            const fEnd = () => {
                                console.log("END");
                            };
                            const stream = await this.api.getStream(this.prompt, fData, fEnd, this.textOpts); // await openAIStream({messages: [{role: 'user', content: this.prompt}], stream: true, model: this.textOpts?.model} as any, this.apiKey);
                        }
                    } catch (e: any) {
                        console.error("App error: " + e);
                        if (e.error) {
                            this.error = e.error.message || `Error code: ${e.error.code}`;
                        }
                        if (e?.response?.data?.error?.message) {
                            this.error = e?.response.data.error.message;
                        }
                    } finally {
                        this.isLoading = false;
                    }
                } else {
                    this.api.abortStream();
                    this.isLoading = false;
                }
            },
        },
        async created() {
            this.apiKey && this.api.setApiKey(this.apiKey);
            this.models = (await this.api.getModels()) || [];
        },
        mounted() {
            const savedSettings = localStorage.getItem("settings");
            if (savedSettings === null) return;
            this.textOpts = JSON.parse(savedSettings);

            const savedKey = localStorage.getItem("key");
            if (!savedKey) this.apiKeyNeeded = true;
        },
    });
