
    import { uniqBy } from "lodash";
import { defineComponent, PropType } from "vue";

    function localeIncludes(str1: string, str2: string) {
        return (str1.toLocaleLowerCase && str1.toLocaleLowerCase()).includes(str2.toLocaleLowerCase && str2.toLocaleLowerCase());
    }


    function localeStart(str1: string, str2: string) {
        return !(str1.toLocaleLowerCase && str1.toLocaleLowerCase()).indexOf(str2.toLocaleLowerCase && str2.toLocaleLowerCase());
    }

    export interface InputTextSuggestion {
        name: string
        value: string
    }

    export default defineComponent({
        props: {
            error: {
                type: Boolean,
                default: () => false
            },
            placeholder: String,
            label: String,
            value: String,
            suggestions: Object as PropType<InputTextSuggestion[]>,
        },
        components: {

        },
        data() {
            return {
                id: "input-text" + +new Date()
            };
        },
        computed: {
            suggestionsFiltered() {
                if (!this.suggestions) return [];
                const byStart = this.suggestions.filter((el) => this.value ? localeStart(el.name, this.value) : true);
                const byInclude = this.suggestions.filter((el) => this.value ? localeIncludes(el.name, this.value) : true);
                return uniqBy([...byStart, ...byInclude], "value");
            },
        },
        methods: {
            suggest(suggestion: InputTextSuggestion) {
                this.$emit("update:value", suggestion.value);
                this.$emit("suggest", suggestion);
            },
        },

        mounted () {
            this.$emit("mounted", this.$refs.input);
        },
    });

    