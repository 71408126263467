import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-317ad04a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center space-x-2" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "text-sm"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["relative inline-block w-9 align-middle select-none", {disabled: _ctx.disabled}])
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        id: _ctx.id,
        name: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
        disabled: _ctx.disabled,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        class: "toggle-checkbox hidden"
      }, null, 40, _hoisted_3), [
        [_vModelCheckbox, _ctx.checked]
      ]),
      _createElementVNode("label", {
        for: _ctx.id,
        class: "toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer"
      }, null, 8, _hoisted_4)
    ], 2)
  ]))
}