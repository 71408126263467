
    import { defineComponent } from "vue";

    export default defineComponent({
        props: {
            value: Number,
            label: String,
        },
        components: {},
        data() {
            return {};
        },
        computed: {},
        methods: {},
    // mounted() {},
    });
