
    import { defineComponent, PropType } from "vue";
    import { CreateCompletionRequest } from "openai";
    import InputNumber from "../misc/InputNumber.vue";
    import InputText, { InputTextSuggestion } from "@/components/misc/InputText.vue";
    import { uniq } from "lodash";
    import ToggleSwitch from "../misc/ToggleSwitch.vue";

    export default defineComponent({
        props: {
            models: {
                type: Array as PropType<string[]>,
                default: () => []
            },
            value: Object as PropType<Partial<CreateCompletionRequest>>,
        },
        components: {
            InputText,
            InputNumber,
            ToggleSwitch
        },
        data() {
            return {
                json: "",
                editJson: false,
            };
        },
        computed: {
            modelsSuggestions(): InputTextSuggestion[] {
                return [...uniq(this.models).map((el) => ({ name: el, value: el }))].reverse();
            },
        },
        methods: {
            show() {
                console.log(this.value?.max_tokens);
            },
        },
        // mounted() {},

        watch: {
            value: {
                handler(newVal) {
                    this.json = JSON.stringify(newVal, null, 2);
                },
                deep: true,
                immediate: true,
            },
        },
    });
